<template>
  <div class="container">
    <div class="statement-print mt-4">
      <h4 style="text-align: center">Распечетка ведомостей</h4>



      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Поиск по курсу</label>
        <div class="col-md-9">
<!--          <input type="text" class="form-control" v-model="courseName"-->
<!--                 @change="onSearch(courseName)"-->
<!--                 placeholder="Введите название курса">-->
          <input type="text" class="form-control" v-model="courseName"
                 placeholder="Введите название курса">


        </div>
      </div>

      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Поиск по преподавателю</label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="teacherName"
                 placeholder="Введите фамилию преподавателя">
        </div>
      </div>

      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Тип семестра</label>
        <div class="col-md-9">
          <select type="text" class="form-control" v-model="chosenSemesterType"
                  placeholder="Выберите тип семестра">
            <option v-for="type in semesterTypes" :value="type.id" :key="type.id">{{type.text}}</option>
          </select>
        </div>
      </div>

      <div class="row mt-4 text-center">
        <div class="col-md-12">
          <Button label="Найти" class="p-button-success" @click="searchCourse" style="margin-right: 5px"/>

          <Button label="Очистить" class="p-button-warning" @click="clearSearch"/>
        </div>
      </div>



      <div class="mt-4 mb-4" v-if="courses.length">
        <!-- Table Start -->
        <DataTable :value="courses" :paginator="true" :rows="10" showGridlines
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[10, 20, 30, 40, 50, 100]"
                   filterDisplay="menu"
                   v-model:filters="filters"
                   currentPageReportTemplate="Показано {last} из {totalRecords}"
                   stripedRows responsiveLayout="scroll">


          <Column field="education_discipline_name" header="Название дисциплины"></Column>
          <Column header="ФИО преподавателя">
            <template #body="{data}">
              {{data.pps_data_lastname}} {{data.pps_data_firstname}}
            </template>
          </Column>

          <Column header="Мудл курс">
            <template #body="{data}">
              <a :href="'https://moodle.uib.kz/course/view.php?id='+ data.mdl_course_id" target="_blank">{{data.course_name}}</a>
            </template>
          </Column>
          <Column field="sp_academic_year_name" header="Учебный год">
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="academicYearNames" placeholder="Любой"
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span v-if="slotProps.value">{{slotProps.value}}</span>
                  <span v-else>{{slotProps.placeholder}}</span>
                </template>
                <template #option="slotProps">
                  <span>{{slotProps.option}}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column v-if="chosenSemesterType !== 2" field="study_course" header="Курс">

            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="studyCourses" placeholder="Любой"
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span v-if="slotProps.value">{{slotProps.value}}</span>
                  <span v-else>{{slotProps.placeholder}}</span>
                </template>
                <template #option="slotProps">
                  <span>{{slotProps.option}}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column v-if="chosenSemesterType !== 2" field="semester" header="Семестр">

            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="semesters" placeholder="Любой"
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span v-if="slotProps.value">{{slotProps.value}}</span>
                  <span v-else>{{slotProps.placeholder}}</span>
                </template>
                <template #option="slotProps">
                  <span>{{slotProps.option}}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column field="language" header="Язык обучения"></Column>
          <Column field="study_level_name" header="Уровень обучения">
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="studyLevels" placeholder="Любой"
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span v-if="slotProps.value">{{slotProps.value}}</span>
                  <span v-else>{{slotProps.placeholder}}</span>
                </template>
                <template #option="slotProps">
                  <span>{{slotProps.option}}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column v-if="chosenSemesterType !== 2" field="exam_type_name" header="Форма экзамена">
            <template #body="{data}">


              <div class="mt-2" v-if="getUser_id == 347 || getUser_id == 129 || getUser_id == 2536">

                <button :class="['btn btn-sm', data.exam_type_name != null ? 'btn-light' : 'btn-primary']"
                        @click="chooseExamType(data.mdl_course_id)">
                  {{ data.exam_type_name != null ? data.exam_type_name : 'Тип экзамена не выбран' }}
                </button>
              </div>
              <div v-else>
                {{data.exam_type_name}}
              </div>


            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="examTypes" placeholder="Любой"
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span v-if="slotProps.value">{{slotProps.value}}</span>
                  <span v-else>{{slotProps.placeholder}}</span>
                </template>
                <template #option="slotProps">
                  <span>{{slotProps.option}}</span>
                </template>
              </Dropdown>
            </template>
          </Column>


          <Column header="Действие">

            <template #body="{data}">

              <ul v-if="chosenSemesterType !== 2">
                <template v-if="data.is_practice == '0'">
                  <li>
                    <router-link :to="'/statement-course-info?mdl_course_id=' + data.mdl_course_id + '&selectable_group_id=' + data.selectable_group_id + '&type=vsk1'" target="_blank">
                      ВСК 1
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="'/statement-course-info?mdl_course_id=' + data.mdl_course_id + '&selectable_group_id=' + data.selectable_group_id + '&type=vsk2'" target="_blank">
                      ВСК 2
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="'/statement-course-info?mdl_course_id=' + data.mdl_course_id + '&selectable_group_id=' + data.selectable_group_id + '&type=exam'" target="_blank">
                      Экзамен
                    </router-link>
                  </li>
                </template>
                <li>
                  <router-link :to="'/statement-course-info?mdl_course_id=' + data.mdl_course_id
                                    + '&selectable_group_id=' + data.selectable_group_id
                                    + '&formation_education_program_id=' + data.formation_education_program_id
                                    + '&is_practice=' + data.is_practice
                                    + '&type=total'"  target="_blank">
                    Итоговый
                  </router-link>
                </li>

              </ul>
              <ul v-else>
                <li>
                  {{data.selectable_group_id}}
                  <router-link :to="'/statement-summer-course-info?mdl_course_id=' + data.mdl_course_id
                                    + '&selectable_group_id=' + data.selectable_group_id
                                    + '&type=total'"  target="_blank">
                    Итоговый
                  </router-link>
                </li>
              </ul>
            </template>


          </Column>
        </DataTable>
        <!-- Table End -->


        <Dialog header="Выбор типа экзамена" v-model:visible="displayChooseExamType" :modal="true" :style="{width: '50vw'}">

          <div>
            <div class="row mt-4">
              <div class="col-md-3">
                <p>Тип экзамена</p>
              </div>
              <div class="col-md-9">
                <select class="form-control form-select"
                        @input="changeEducationCourseExamType($event)">
                  <option v-for="(examTypes, index) in [{id: 0, name: 'Выберите тип экзамена'}, ...teacher_form.examTypes]"
                          :value="examTypes.id"
                          :key="index">{{ examTypes.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <template #footer>
            <Button label="Отмена" icon="pi pi-times" class="p-button-text" @click="closeChooseExamType"/>
            <Button label="Сохранить" icon="pi pi-check" @click="putEducationCourseExamType"/>
          </template>

        </Dialog>
      </div>



    </div>
  </div>


</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: "StatementPrint",
  data() {
    return {
      courseName: '',
      teacherName: '',
      courses: [],

      filters: {
        'study_level_name': {value: null, matchMode: FilterMatchMode.EQUALS},
        'semester': {value: null, matchMode: FilterMatchMode.EQUALS},
        'study_course': {value: null, matchMode: FilterMatchMode.EQUALS},
        'exam_type_name': {value: null, matchMode: FilterMatchMode.EQUALS},
        'sp_academic_year_name': {value: null, matchMode: FilterMatchMode.EQUALS},
      },
      studyLevels: ['Бакалавриат', 'Магистратура (1 годичное,профильное)', 'Магистратура (1,5 годичное, профильное)',
        'Магистратура (2 годичное, научно-педагогическое)', 'Докторантура (научно-педагогическое направление)', 'MBA', 'EMBA'],
      studyCourses: [1, 2, 3, 4],
      examTypes: ['Устно', 'Письменно (слепая проверка)', 'Тест на компьютере', 'Проект', 'Take-home open book', 'Сдача нормативов'],
      semesters: [1, 2 , 3 , 4 , 5 , 6, 7, 8],
      academicYearNames: [],

      chosenSemesterType: 1,
      semesterTypes: [{id: 1, text: 'Семестровый'}, {id: 2, text: 'Летний'}],

      displayChooseExamType: false,
      selected_mdl_course_id: null,
      selected_exam_type_id: null
    }
  },
  computed: {
    ...mapState('statementPrint', ['statementPrint_form']),
    ...mapState('teacher', ['teacher_form']),
    ...mapState('orders', ['orders_form']),
    getUser_id () {
      return getCookie('USER_ID');
    }
  },
  watch: {
  //   onSearch(search) {
  //     this.GET_COURSES_BY_NAME(search)
  //   }

    chosenSemesterType(){
      this.courses = []
    },

    courses(){
      console.log(this.courses)
    }
  },
  methods: {
    ...mapActions('statementPrint', ['GET_COURSES_BY_NAME', 'GET_COURSES_BY_PPS_LAST_NAME', 'GET_SUMMER_COURSES']),
    ...mapActions('teacher', ['GET_EXAM_TYPES', 'PUT_EDUCATION_COURSE_EXAM_TYPE']),
    ...mapActions('orders', ['GET_SP_ACADEMIC_YEAR']),

    getActiveAcademicYears(data) {
      const result = [];
      let activeIndex = -1;

      for (let i = 0; i < data.length; i++) {
        if (data[i].is_active === 1) {
          activeIndex = i;
          break;
        }
      }

      if (activeIndex > 1) {
        result.push(data[activeIndex - 2].name);
        result.push(data[activeIndex - 1].name);
        result.push(data[activeIndex].name);
      }

      return result;
    },

    async chooseExamType(mdl_course_id) {
      await this.GET_EXAM_TYPES()
      this.displayChooseExamType = true
      this.selected_mdl_course_id = mdl_course_id
    },
    closeChooseExamType() {
      this.displayChooseExamType = false
    },

    async putEducationCourseExamType() {
      this.displayChooseExamType = false
      await this.PUT_EDUCATION_COURSE_EXAM_TYPE({mdl_course_id: this.selected_mdl_course_id, exam_type_id: this.selected_exam_type_id})
      if (this.courseName.length > 1) {
        this.GET_COURSES_BY_NAME(this.courseName).then(json => (this.courses = json));
      }
      else if (this.teacherName.length > 1) {
        this.GET_COURSES_BY_PPS_LAST_NAME(this.teacherName).then(json => (this.courses = json));
      }

    },
    changeEducationCourseExamType( e, val = 'value') {
      const value = e.target[val]

      this.selected_exam_type_id = value
      console.log(this.selected_exam_type_id, 'selected_exam_type_id')
    },
    // onSearch(search) {
    //   this.GET_COURSES_BY_NAME(search)
    // },
    // onSearch2(search) {
    //   if (search.length > 3) {
    //     this.GET_COURSES_BY_NAME(search).then(json => (this.courses = json));
    //   }
    // },
    searchCourse() {
      if (this.chosenSemesterType === 1){
        if (this.courseName.length > 1) {
          this.GET_COURSES_BY_NAME(this.courseName).then(json => (this.courses = json));
        }
        else if (this.teacherName.length > 1) {
          this.GET_COURSES_BY_PPS_LAST_NAME(this.teacherName).then(json => (this.courses = json));
        }
      } else {
        this.GET_SUMMER_COURSES({name: this.courseName, lastname: this.teacherName}).then(json => (this.courses = json));
      }

    },
    clearSearch() {
      this.courseName = ''
      this.teacherName = ''
      this.courses = []
    }
  },
  async mounted() {
    await this.GET_SP_ACADEMIC_YEAR();
    this.academicYearNames = this.getActiveAcademicYears(this.orders_form.academicYear);
  }
}
</script>

<style scoped></style>
